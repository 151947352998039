// NEW COLORS

// Neutral
$neutral-0: #FFFFFF;
$neutral-1: #FCFCFC;
$neutral-2: #F9F9F9;
$neutral-3: #F0F0F0;
$neutral-4: #E8E8E8;
$neutral-5: #E1E1E1;
$neutral-6: #D9D9D9;
$neutral-7: #CECECE;
$neutral-8: #BBBBBB;
$neutral-9: #626262;
$neutral-10: #4A4A4A;
$neutral-11: #3D3D3D;
$neutral-12: #1F1F1F;

// Red
$red-1: #FFFCFB;
$red-2: #FFF8F6;
$red-3: #FFEAE6;
$red-4: #FFD9D1;
$red-5: #FFCABF;
$red-6: #FFB8AC;
$red-7: #FFA395;
$red-8: #F78676;
$red-9: #E50000;
$red-10: #D50000;
$red-11: #BA0000;
$red-12: #641E17;

// Violet
$violet-1: #FFFBFF;
$violet-2: #FEF7FD;
$violet-3: #FFE9FD;
$violet-4: #FDDBFB;
$violet-5: #F9CDF6;
$violet-6: #F3BCF0;
$violet-7: #EAA5E6;
$violet-8: #DF86DB;
$violet-9: #990099;
$violet-10: #880088;
$violet-11: #711170;
$violet-12: #5F005F;

// Surrogate
$surrogate-1: #FFFCFD;
$surrogate-2: #FEF7F8;
$surrogate-3: #FFE9ED;
$surrogate-4: #FFDBE2;
$surrogate-5: #FFDBE2;
$surrogate-6: #F6BEC9;
$surrogate-7: #EEABB9;
$surrogate-8: #E493A4;
$surrogate-9: #C7105C;
$surrogate-10: #B80050;
$surrogate-11: #9B1448;
$surrogate-12: #65142F;

// Green
$green-1: #FBFEFA;
$green-2: #F6FBF4;
$green-3: #E7F8E0;
$green-4: #D8F2CC;
$green-5: #C8EAB7;
$green-6: #B5DEA0;
$green-7: #9CCD83;
$green-8: #7AB957;
$green-9: #64B32C;
$green-10: #58A71A;
$green-11: #487E25;
$green-12: #2A3F1E;

// Blue
$blue-1: #FDFDFE;
$blue-2: #F6FAFF;
$blue-3: #ECF2FE;
$blue-4: #DDEAFF;
$blue-5: #CDE0FF;
$blue-6: #B8D3FF;
$blue-7: #A1C1F8;
$blue-8: #7FA8EE;
$blue-9: #1750B5;
$blue-10: #043FA3;
$blue-11: #06378D;
$blue-12: #172F5B;

// Turquoise
$turquoise-1: #FAFEFE;
$turquoise-2: #F4FBFB;
$turquoise-3: #E2F6F7;
$turquoise-4: #D0F0F2;
$turquoise-5: #BCE6E9;
$turquoise-6: #A8D9DD;
$turquoise-7: #8CC8CC;
$turquoise-8: #60B1B7;
$turquoise-9: #007E85;
$turquoise-10: #007077;
$turquoise-11: #06575C;
$turquoise-12: #174043;

// Yellow
$yellow-1: #FEFDFB;
$yellow-2: #FFFAE9;
$yellow-3: #FFF3C1;
$yellow-4: #FFE99C;
$yellow-5: #FFDE77;
$yellow-6: #FCD274;
$yellow-7: #E9C166;
$yellow-8: #D7A937;
$yellow-9: #FFC500;
$yellow-10: #F7BE1C;
$yellow-11: #856A29;
$yellow-12: #46391D;

// Orange
$orange-1: #FFFCF9;
$orange-2: #FFF5EE;
$orange-3: #FFEADB;
$orange-4: #FFD8BC;
$orange-5: #FFCBA6;
$orange-6: #FFBC8C;
$orange-7: #FFA96C;
$orange-8: #FF8E3E;
$orange-9: #EE7100;
$orange-10: #E96100;
$orange-11: #C45100;
$orange-12: #672B00;

// white
$color-white--100: #ffffff;

// black
$color-black--100: #000000;
$color-black--80: #333333;
$color-black--60: #666666;
$color-black--40: #999999;
$color-black--20: #cccccc;
$color-black--10: #e6e6e6;
$color-black--05: #f2f2f2;
$color-black--03: #f7f7f7;

$color-black: (
  '100': $color-black--100,
  '80': $color-black--80,
  '60': $color-black--60,
  '40': $color-black--40,
  '20': $color-black--20,
  '10': $color-black--10,
  '05': $color-black--05,
  '03': $neutral-1,
);

// red
$color-red--100: #ff0000;
$color-red--80: #ff3333;
$color-red--60: #ff6666;
$color-red--40: #ff9999;
$color-red--20: #ffcccc;
$color-red--10: #ffe6e6;
$color-red--05: #fff2f2;
$color-red--03: #fff7f7;

$color-red: (
  '100': $color-red--100,
  '80': $color-red--80,
  '60': $color-red--60,
  '40': $color-red--40,
  '20': $color-red--20,
  '10': $color-red--10,
  '05': $color-red--05,
  '03': $color-red--03,
);

// violet
$color-violet--100: #990099;
$color-violet--80: #ad33ad;
$color-violet--60: #c266c2;
$color-violet--40: #d699d6;
$color-violet--20: #ebcceb;
$color-violet--10: #f5e6f5;
$color-violet--05: #faf2fa;
$color-violet--03: #fcf7fc;

$color-violet: (
  '100': $color-violet--100,
  '80': $color-violet--80,
  '60': $color-violet--60,
  '40': $color-violet--40,
  '20': $color-violet--20,
  '10': $color-violet--10,
  '05': $color-violet--05,
  '03': $color-violet--03,
);

// surrogate
$color-surrogate--100: #c7125c;
$color-surrogate--80: #d2417d;
$color-surrogate--60: #dd719e;
$color-surrogate--40: #e9a0be;
$color-surrogate--20: #f4d0df;
$color-surrogate--10: #fae8f0;
$color-surrogate--05: #fcf3f7;
$color-surrogate--03: #fdf8fa;

$color-surrogate: (
  '100': $color-surrogate--100,
  '80': $color-surrogate--80,
  '60': $color-surrogate--60,
  '40': $color-surrogate--40,
  '20': $color-surrogate--20,
  '10': $color-surrogate--10,
  '05': $color-surrogate--05,
  '03': $color-surrogate--03,
);

// turquoise
$color-turquoise--100: #009aa3;
$color-turquoise--80: #33aeb5;
$color-turquoise--60: #66c3c8;
$color-turquoise--40: #99d7da;
$color-turquoise--20: #ccebed;
$color-turquoise--10: #e6f5f6;
$color-turquoise--05: #f2fafa;
$color-turquoise--03: #f7fcfc;

$color-turquoise: (
  '100': $color-turquoise--100,
  '80': $color-turquoise--80,
  '60': $color-turquoise--60,
  '40': $color-turquoise--40,
  '20': $color-turquoise--20,
  '10': $color-turquoise--10,
  '05': $color-turquoise--05,
  '03': $color-turquoise--03,
);

// orange
$color-orange--100: #ee7100;
$color-orange--80: #f18d33;
$color-orange--60: #f5a966;
$color-orange--40: #f8c699;
$color-orange--20: #fce2cc;
$color-orange--10: #fdf1e6;
$color-orange--05: #fef8f2;
$color-orange--03: #fefbf7;

$color-orange: (
  '100': $color-orange--100,
  '80': $color-orange--80,
  '60': $color-orange--60,
  '40': $color-orange--40,
  '20': $color-orange--20,
  '10': $color-orange--10,
  '05': $color-orange--05,
  '03': $color-orange--03,
);

// green
$color-green--100: #64b32c;
$color-green--80: #83c256;
$color-green--60: #a2d180;
$color-green--40: #c2e1ab;
$color-green--20: #e0f0d5;
$color-green--10: #eff7ea;
$color-green--05: #f7fbf4;
$color-green--03: #fafdf9;

$color-green: (
  '100': $color-green--100,
  '80': $color-green--80,
  '60': $color-green--60,
  '40': $color-green--40,
  '20': $color-green--20,
  '10': $color-green--10,
  '05': $color-green--05,
  '03': $color-green--03,
);

// blue
$color-blue--100: #14459c;
$color-blue--80: #436bb0;
$color-blue--60: #7290c4;
$color-blue--40: #a1b5d7;
$color-blue--20: #d0daeb;
$color-blue--10: #e8edf5;
$color-blue--05: #f3f6fa;
$color-blue--03: #f8f9fc;

$color-blue: (
  '100': $color-blue--100,
  '80': $color-blue--80,
  '60': $color-blue--60,
  '40': $color-blue--40,
  '20': $color-blue--20,
  '10': $color-blue--10,
  '05': $color-blue--05,
  '03': $color-blue--03,
);

// yellow
$color-yellow--100: #ffcc00;
$color-yellow--80: #ffd633;
$color-yellow--60: #ffe066;
$color-yellow--40: #ffeb99;
$color-yellow--20: #fff5cc;
$color-yellow--10: #fffae6;
$color-yellow--05: #fffcf2;
$color-yellow--03: #fffdf7;

$color-yellow: (
  '100': $color-yellow--100,
  '80': $color-yellow--80,
  '60': $color-yellow--60,
  '40': $color-yellow--40,
  '20': $color-yellow--20,
  '10': $color-yellow--10,
  '05': $color-yellow--05,
  '03': $color-yellow--03,
);

$colors: (
  black: $color-black,
  red: $color-red,
  violet: $color-violet,
  surrogate: $color-surrogate,
  turquoise: $color-turquoise,
  orange: $color-orange,
  green: $color-green,
  blue: $color-blue,
  yellow: $color-yellow,
);

// company colors
$color-twitter: #00acee;
$color-linkedin: #0a66c2;
$color-xing-dark: #126567;
$color-xing-light: #afd400;
