@import '../../../styles/index';

.base {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  flex-direction: column;
  justify-content: stretch;
}

.visible {
  display: flex;

  @include media-from(sm) {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 0.5rem;
  box-shadow: $shadow-configurator-navigation-header;
}

.headline {
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
}

.icon {
  color: $surrogate-9;
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  padding: 0 1.125rem 1rem;
  flex-grow: 1;
  overflow: auto;

  & hr:first-child {
    display: none;
  }

  & h3 {
    font-weight: $font-weight-regular;
    font-size: $font-size-18;
  }
}

.footer {
  padding: 1rem;
  text-align: center;

  & > .button {
    font-size: $font-size-18;
  }

  & > div:not(:first-child) {
    margin-top: 1rem;
  }
}

.reset-filter {
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  color: $surrogate-9;
  margin: 1.5rem 0;
  background: none;
  border: none;
}
