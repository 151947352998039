$font-size-10: 0.625rem; // 10px
$font-size-12: 0.75rem; // 12px
$font-size-14: 0.875rem; // 14px
$font-size-16: 1rem; // 16px
$font-size-18: 1.125rem; // 18px
$font-size-20: 1.25rem; // 20px;
$font-size-22: 1.375rem; // 22px
$font-size-24: 1.5rem; // 24px
$font-size-28: 1.75rem; // 28px
$font-size-30: 1.875rem; // 30px
$font-size-32: 2rem; // 32px
$font-size-34: 2.125rem; // 34px
$font-size-36: 2.25rem; // 36px
$font-size-44: 2.75rem; // 44px
$font-size-48: 3rem; // 48px
$font-size-64: 4rem; // 64px
$font-size-72: 4.5rem; // 72px
$font-size-100: 6.25rem; // 100px
