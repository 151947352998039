@import '../../styles/index';

.main {
  min-width: calc(100vw - 3rem);
  width: 100%;
  position: relative;

  @include media-from(xs) {
    min-width: unset;
    width: 22.875rem;
  }

  & i {
    color: $turquoise-9;
  }
}

.form-field {
  margin: 0.5rem auto 0.75rem auto;
  min-width: calc(100vw - 3rem);
  width: 100%;

  @include media-from(xs) {
    min-width: unset;
  }

  &-with-error {
    padding-bottom: 0;
  }
}

.box {
  border-radius: 3px 0 3px 3px;
  background-color: rgba(0, 154, 163, 0.08);
  padding: 0.75rem 1rem;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $turquoise-9;

  @include media-from(xs) {
    min-width: unset;
    width: calc(100% + 1.8rem);
  }

  &::before {
    position: absolute;
    right: 0;
    top: -1rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 16px 16px;
    border-color: transparent transparent rgba(0, 154, 163, 0.08) transparent;

    @include media-from(xs) {
      right: -1.8rem;
      content: '';
    }
  }
}
