@import '../functions/index';
@import '../variables/index';
@import 'accent';

@import 'responsive-properties';

@mixin space-between-elements-margin($space, $space-sm: '') {
  margin-bottom: $space * 0.5;

  @include media-from(sm) {
    margin-bottom: if($space-sm == '', $space, $space-sm);
  }
}

@mixin space-between-elements($space: 6rem, $space-sm: '') {
  & > *:not(:last-child) {
    @include space-between-elements-margin($space, $space-sm);
  }
}

@mixin space-between-containers() {
  &[data-is-container]:not(:last-child) {
    @include space-between-elements-margin(
      $space-between-containers,
      $space-between-containers-sm
    );
  }
}

@mixin horizontal-padding-with-safe-area($value) {
  padding-left: with-save-area($value, left);
  padding-right: with-save-area($value, right);
}
