@import '../../styles/index';

body.body {
  background-color: $neutral-0;

  strong {
    font-weight: 500;
  }

  :global(.Box-module-shadow) {
    box-shadow: $shadow-configurator-light;
  }

  :global(.BoxWithArrow-module-base) {
    border: none;
    box-shadow: $shadow-configurator-light;
  }

  :global(.CheckBoxCard-module-active > .CheckBoxCard-module-label) {
    color: $surrogate-9;
  }

  :global(.CheckBoxCard-module-label) {
    color: $surrogate-3;
  }
}

.base {
  background-color: $neutral-0;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
