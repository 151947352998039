@import '../../styles/index';

.base {
  width: 100%;
  background-color: $neutral-0;
  box-shadow: $shadow-configurator-light;
  border-radius: $border-radius-default;
  margin-left: auto;
  margin-right: auto;
}

.text {
  padding: 2rem 1.5rem 1rem;
  font-size: $font-size-16;
  color: $neutral-12;

  @include media-from(xs) {
    font-size: $font-size-18;
  }

  p {
    font-weight: $font-weight-regular;
    line-height: 1.55;
  }

  ul li {
    padding-left: 1.8rem;
    margin-bottom: 0.75rem;
    line-height: 1.4;

    &::before {
      height: 0.8rem;
      width: 0.8rem;
      margin-left: -1.8rem;
      transform: translateY(0.45rem);
    }
  }

  @include media-from(sm) {
    padding: 2rem 2rem 1.5rem;
  }
}

.checkbox {
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.checked .label {
    color: $surrogate-9;
  }

  @include media-from(sm) {
    padding: 2rem 1.5rem;
  }

  > :first-child {
    margin-right: 1rem;
    flex-shrink: 0;
  }
}

.label {
  font-size: 17px;
  font-weight: $font-weight-medium;
  color: $neutral-9;
  transition: color $duration-default;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.default {
  max-width: 515px;
}

.large {
  max-width: 640px;

  & .checkbox {
    justify-content: start;
  }
}

.flat-style {
  max-width: unset;
  border: 2px solid $neutral-7;
  border-radius: 6px;
  box-shadow: none;
  transition: border $duration-default;

  .label {
    font-size: 1.125rem;
    line-height: 1.3;
    pointer-events: none;
  }

  .checkbox {
    justify-content: start;
    padding: 1.5rem 1.25rem;
    border-top: 2px solid $neutral-7;
    transition: background $duration-default, border $duration-default;
    cursor: pointer;

    &.checked {
      border-top: 2px solid $surrogate-9;
      background-color: rgba($surrogate-9, 0.05);
    }
  }

  .text {
    padding: 1.25rem;

    @include media-from(sm) {
      padding: 1.5rem;
    }
  }
}

.flat-checked {
  border: 2px solid $surrogate-9;
}
