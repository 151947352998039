@import '../../styles/index';

.base {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1.5rem;
  background-color: $neutral-0;
  box-shadow: $shadow-district-heat-map-search;
  z-index: $index-map-filter-menu;

  @include media-from(sm) {
    left: 0;
    right: 0;
    bottom: 1rem;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    border-radius: 0.375rem;
  }

  .filter-toggle-desktop {
    display: none;
    transition: transform $duration-default, top $duration-default;

    @include media-from(sm) {
      position: absolute;
      left: 0;
      right: 0;
      top: -4.5rem;
      display: flex;

      &.active {
        top: -4.125rem;
      }
    }

    &:hover {
      transform: translateY(-0.25rem);
    }
  }

  .expand-toggle {
    left: auto;
    right: 1rem;
    transform: none;

    @include media-from(sm) {
      display: none;
    }
  }
}

.checkbox {
  margin-right: 0.75rem;
}

.label {
  display: flex;
  align-items: center;
  color: $surrogate-7;
  font-size: $font-size-18;
  cursor: pointer;

  &.active {
    color: $surrogate-9;
  }
}
