@import '../../styles/index';

.headline {
  color: $green-9;
  font-size: $font-size-28;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  max-width: 19rem;
  margin: 0 auto 1.5rem !important;
  text-align: center;
}

.description {
  font-weight: $font-weight-light;
  font-size: $font-size-20;
  line-height: 1.4;
  margin: 0;
  text-align: center;
}
