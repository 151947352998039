.base {
  display: flex;
}

.base > * {
  flex: 1;
}

.base[data-justify='center'] {
  justify-content: center;
}
