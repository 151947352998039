@import '../../../styles/index';

.headline {
  white-space: nowrap;
  font-weight: $font-weight-medium;
}

.subtext {
  color: $neutral-9;
  margin-top: 8px;

  @include media-to(sm) {
    margin-top: 0;
  }
}
