@import '../../styles/index';

.text {
  margin: 0;
  padding: 0;
  font-family: Euclid Enercity;
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 1.5;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-16;
  }
}
