@import '../../styles/index';

.base {
  display: grid;
  grid-template-areas: 'headline content';
  column-gap: 56px;
  row-gap: 25px;

  @include media-to(sm) {
    grid-template-areas: 'headline' 'content';
    row-gap: 0;

    & > div:nth-child(even) {
      margin-bottom: 16px;
    }

    & > div:nth-child(odd) {
      margin-bottom: 6px;
    }
  }
}
