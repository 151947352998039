@import '../../styles/index';

.base {
  padding: 0.625rem 1.75rem;
  border-radius: 0.25rem;
}

.base,
.base p,
.base strong {
  color: $neutral-0;
  font-weight: $font-weight-bold;
  font-size: $font-size-16;
  margin-block-start: 0;
  margin-block-end: 0;
}

.yellow {
  background: $yellow-9;
  color: $neutral-12;
}

.green {
  background: $green-9;
  color: $neutral-12;
}

.turquoise {
  background: $turquoise-9;
}

.blue {
  background: $blue-9;
}

.orange {
  background: $orange-9;
  color: $neutral-12;
}

.purple {
  background: $violet-9;
}

.white {
  color: $surrogate-9;
}
