@import '../../styles/index';

.base {
  margin: 0;
}

.title {
  margin-bottom: 0.5rem;

  @include media-from(sm) {
    margin-bottom: 1.25rem;
  }
}

.location {
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  color: $surrogate-9;
  line-height: 1.438;
  margin-bottom: 0.5rem;

  @include media-from(sm) {
    margin-bottom: 1rem;
  }
}

.description {
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  line-height: 1.6;

  @include media-from(sm) {
    font-size: 1.25rem;
    line-height: 32px;
  }
}

.details {
  @extend %list-checkmark;

  li {
    font-size: $font-size-18;
    font-weight: $font-weight-light;
    margin-bottom: 1rem;

    @include media-from(sm) {
      margin-bottom: 2rem;
      padding-left: 2.75rem;
      font-size: $font-size-20;

      &::before {
        width: 2rem;
        height: 2rem;
        margin-left: -2.75rem;
        transform: translateY(-0.1rem);
      }
    }

    strong {
      font-weight: $font-weight-medium;
      display: block;
    }
  }

  @include media-from(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.divider {
  width: 100%;
  margin: 2rem 0;
  border: 0;
  height: 1px;
  border-top: 1px solid $surrogate-4;

  @include media-from(sm) {
    margin: 3rem 0;
  }
}
