@import '../../styles/index';

.base {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: $neutral-12;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-14;
  margin: 0;
  outline: none;
  padding: 1rem 0;

  & + & {
    border-top: 1px solid $surrogate-3;
  }

  @include media-from(lg) {
    border: 1px solid $surrogate-4;
    border-radius: 100px;
    font-size: $font-size-16;
    padding: 0.75rem 1.25rem;

    &:hover {
      background-color: $surrogate-1;
      border-color: $surrogate-9;
    }

    &:active {
      background-color: $neutral-0;
      border-color: $surrogate-9;
      box-shadow: 0 0 0 4px rgba($surrogate-9, 0.12);
    }

    & + &:not(:hover):not(:active) {
      border-top-color: $surrogate-4;
    }
  }
}
