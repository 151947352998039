$space-between-sections: 2rem;
$space-between-sections-xs: 3rem;
$space-between-sections-sm: 6rem;

$large-space-between-sections: 4rem;
$large-space-between-sections-xs: 4rem;
$large-space-between-sections-sm: 8.5rem;

$space-between-split-section-items: $space-between-sections;
$space-between-split-section-items-sm: $space-between-sections-sm;
$space-between-fields: 0.875rem;
$space-between-containers: 2rem;
$space-between-containers-sm: 3rem;

$container-size-default: 1280px;
$container-size-small: 960px;
$container-size-tiny-plus: 845px;
$container-size-tiny: 750px;
$container-size-micro: 640px;

$button-height: 3.75rem;
$button-medium-height: 3.125rem;
$button-small-height: 1.875rem;
$section-border-width: 1.25rem;
$section-padding-horizontal-mobile: 1rem;
$badge-xxs: 7.5rem;
$badge-sm: 8.875rem;
$stagebox-badge-shift-top: $badge-sm * 0.5492957746;
$stagebox-badge-shift-right: $badge-sm * 0.323943662;
$stagebox-text-padding-vertical: 2rem;
$layout-max-width: 1440px;
$bottom-bar-padding: 1.5rem;
