@import '../../styles/index';

.base {
  align-items: center;
  flex-direction: column;
  display: none;
  width: 100%;

  @include media-from(sm) {
    flex-direction: row;
    display: flex;
  }
}

.logo {
  width: 100%;
  margin-bottom: 2rem;

  @include media-from(sm) {
    width: 17rem;
    margin-right: 3rem;
    margin-bottom: 0;
  }
}

.hr {
  width: 100%;
  background-color: $surrogate-6;
  border: 1px solid $surrogate-6;
  border-radius: 100px;
}
