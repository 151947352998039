@import '../../../styles/index';

.item {
  display: flex;
  padding: 0.625rem;
  border-radius: $border-radius-small;
  color: $neutral-9;
  cursor: pointer;

  &:hover,
  &.focus {
    background-color: $surrogate-3;
  }

  .text {
    margin-left: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-18;
  }

  &.active {
    color: $surrogate-9;
  }

  &.indented {
    padding-left: 1.625rem;
  }
}
