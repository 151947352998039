@import '../../../../styles/index';
.base {
  display: flex;
  justify-content: center;
}

.pictogram-wrapper {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @include media-to(lg) {
    max-width: 566px;
  }
}

.flashes {
  position: absolute !important;
  left: -4%;
  top: 0%;
  width: 26% !important;
  height: 300px !important;

  @include media-to(lg) {
    height: auto;
    top: -15% !important;
  }

  @media (min-width: map-get($breakpoints, 'xl')) and (max-height: 900px) {
    top: 2%;
    left: 12%;
    height: 180px !important;
  }
}
