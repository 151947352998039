@import '../../../styles/index';

.base {
  display: flex;
  align-items: center;
  color: $surrogate-9;
  margin: 0.5rem;

  :hover {
    cursor: pointer;
  }
}

.icon {
  margin-right: 0.5rem;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
}
