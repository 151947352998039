@import '../../styles/index';

.base {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  @include media-from(md) {
    border-left: 2px solid $neutral-7;
  }
}

.items {
  margin-bottom: 2rem;

  > div:not(:last-child) {
    @include media-from(md) {
      margin-bottom: 4rem;
    }
  }
}
