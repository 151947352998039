@import '../../styles/index';

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 100%;

  &.children-space-default {
    @include space-between-elements();
  }

  &.children-space-none {
    @include space-between-elements(0);
  }

  &.children-space-small {
    @include space-between-elements(3rem);
  }

  // only for configurator
  &.children-space-tiny {
    @include space-between-elements(4rem);
  }

  &.children-space-mini {
    @include space-between-elements(2rem);
  }

  &.children-space-micro {
    @include space-between-elements(1rem);
  }
}

.max {
  width: 100%;
}

.default {
  max-width: $container-size-default;
}

.small {
  max-width: $container-size-small;
}

.tiny-plus {
  max-width: $container-size-tiny-plus;
}

.tiny {
  max-width: $container-size-tiny;
}

.micro {
  max-width: $container-size-micro;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left {
  align-items: flex-start;
  text-align: left;
}

.center,
.align-center {
  align-items: center;
  text-align: center;
}

.left {
  align-items: flex-end;
  text-align: left;
}

.offset-top {
  position: relative;
  max-width: none;
  margin-top: 5.6875rem !important;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: $neutral-0;
    position: absolute;
    z-index: $index-container-top;
    top: 25%;
    left: 0;

    @include media-from(sm) {
      top: 50%;
    }
  }

  > * {
    position: relative;
    z-index: $index-container;
    flex-direction: row !important;
    justify-content: center;
    overflow-x: hidden;
    width: 100vw !important;
    left: -2.25rem !important;

    @include media-from(md) {
      width: 100% !important;
      left: unset !important;
      justify-content: space-around;
    }
  }

  figure {
    min-width: 200px;
    max-width: 300px;
  }

  figure:first-child:not(:last-child) {
    transform: none;
    right: 1rem;

    @include media-from(md) {
      right: 2rem;
    }
  }

  figure:last-child:not(:first-child) {
    transform: none;
    left: 1rem;

    @include media-from(md) {
      left: 2rem;
    }
  }
}
