@import '../../styles/index';

.wrapper {
  display: block;
  width: 100%;
}

.base {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @include media-from(xs) {
    flex-direction: row;

    gap: 1rem;
  }

  & > div {
    width: 100%;
  }
}
