@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
}

.form-field {
  label,
  input {
    cursor: pointer;
    user-select: none;
  }
  span,
  input {
    pointer-events: none;
  }

  svg {
    transition: transform $duration-default;
    pointer-events: none;
  }
}

.is-open {
  z-index: initial;
  .form-field svg {
    transform: rotate(180deg);
  }
}

.list {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0.75rem;
  padding: 10px 10px 10px 10px;
  position: absolute;
  width: 100%;
  z-index: $index-form-field-dropdown-list;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: $neutral-0;
  margin-bottom: 10rem;
  max-height: 22.5rem;

  &.with-scrollbar {
    overflow-y: scroll;
    padding: 10px 0 10px 10px;

    &::-webkit-scrollbar {
      width: 28px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      background: $surrogate-4;
      border: 10px solid transparent;
      background-clip: padding-box;
    }
  }
}

.placeholder-number {
  display: none;
  background-color: $surrogate-9;
  color: $neutral-0;
  border-radius: 4px;
  height: 1.625rem;
  width: 1.625rem;
  font-size: $font-size-18;
  line-height: 1.1;
  margin-right: 0.75rem;
  align-items: center;
  justify-content: center;

  &.is-active {
    display: flex;
  }

  &.with-gradient {
    color: $surrogate-9;
    background-color: $neutral-0;
  }
}
