@import '../../styles/index';

.base {
  margin: 0 auto;
  max-width: 350px;
  margin-top: -3rem;
  margin-bottom: -1.5rem !important;

  @include media-from(sm) {
    max-width: 450px;
    margin-top: -4rem;
    margin-bottom: -1rem !important;
  }
}

.without-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
