$shadow-standard: 0 10px 10px 0 rgba(248, 209, 209, 0.06),
  0 0 2px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$shadow-big: 0 2px 30px 4px rgba(0, 0, 0, 0.2);
$shadow-hoverable: 0 2px 4px rgba(0, 0, 0, 0.15), 0 6px 24px rgba(0, 0, 0, 0.1);
$shadow-hoverable-hovered: 0 8px 16px rgba(0, 0, 0, 0.15),
  0 18px 48px rgba(0, 0, 0, 0.1);
$shadow-button: 0 1px 3px 0 rgba(0, 0, 0, 0.08),
  0 4px 12px 0 rgba(0, 0, 0, 0.08);
$shadow-button-hover: 0 3px 6px rgba(0, 0, 0, 0.08),
  0 8px 24px rgba(0, 0, 0, 0.08);
$shadow-button-focus: 0 1px 3px 0 rgba(199, 18, 92, 0.12),
  0 8px 16px 0 rgba(199, 18, 92, 0.12), 0 0 0 4px rgba(199, 18, 92, 0.12);

$shadow-button-link-hover: 0 4px 24px rgba(0, 0, 0, 0.12);

$shadow-standard-vorfahren: 0 10px 10px 0 rgba(248, 209, 209, 0.06),
  0 0 2px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

$shadow-hoverable-focused: 0 2px 4px rgba(199, 18, 178, 0.15),
  0 6px 24px rgba(199, 18, 178, 0.1);
$shadow-stagebox-magazine: 0px 4px 20px rgba(0, 0, 0, 0.15);
$shadow-regio-stagebox: 0px 0px 1px rgba(0, 0, 0, 0.3),
  0px 4px 24px rgba(0, 0, 0, 0.15);

$shadow-configurator-light: 0 1px 3px rgba(0, 0, 0, 0.08),
  0 4px 64px rgba(0, 0, 0, 0.08);
$shadow-configurator-active: 0 1px 3px rgba($surrogate-9, 0.12),
  0 4px 16px rgba($surrogate-9, 0.12);
$shadow-configurator: 0 1px 3px rgba(0, 0, 0, 0.08),
  0 4px 16px rgba(0, 0, 0, 0.08);
$shadow-configurator-navigation-header: 0px 4px 24px rgba(0, 0, 0, 0.08);
$shadow-configurator-navigation-item: 0px 4px 24px rgba(0, 0, 0, 0.08),
  0px 0px 2px rgba(0, 0, 0, 0.04);
$shadow-configurator-navigation-item-hovered: 0px 0px 0px 4px
    rgba(199, 18, 92, 0.05),
  0px 4px 24px rgba(199, 18, 92, 0.12), 0px 0px 2px rgba(199, 18, 92, 0.04);
$shadow-job-apply: 0 8px 24px 0 rgba(0, 0, 0, 0.08),
  0 1px 3px 0 rgba(0, 0, 0, 0.08);
$shadow-error-ring: inset 0rem 0rem 0rem 0.125rem $red-9;
$shadow-configurator-seal: 0px 4px 24px rgba(0, 0, 0, 0.124771);

// buttons
$shadow-product-overview-button-orange: 0 1px 3px 0 $orange-4,
  0 8px 16px 0 $orange-4, 0 0 0 4px $orange-4;
$shadow-product-overview-button-yellow: 0 1px 3px 0 $yellow-4,
  0 8px 16px 0 $yellow-4, 0 0 0 4px $yellow-4;
$shadow-product-overview-button-green: 0 1px 3px 0 $green-4,
  0 8px 16px 0 $green-4, 0 0 0 4px $green-4;
$shadow-product-overview-button-turquoise: 0 1px 3px 0 $turquoise-4,
  0 8px 16px 0 $turquoise-4, 0 0 0 4px $turquoise-4;
$shadow-product-overview-button-blue: 0 1px 3px 0 $blue-4,
  0 8px 16px 0 $blue-4, 0 0 0 4px $blue-4;
$shadow-product-overview-button-violet: 0 1px 3px 0 $violet-4,
  0 8px 16px 0 $violet-4, 0 0 0 4px $violet-4;

// forms
$shadow-form-field-surrogate-active: 0px 0px 0px 4px rgba(199, 18, 92, 0.12);
$shadow-form-field-turquoise-active: 0px 0px 0px 4px rgba(0, 154, 163, 0.12);
$shadow-form-field-orange-active: 0px 0px 0px 4px rgba(238, 113, 0, 0.12);

$shadow-form-field-radio-input-focus: 0px 0px 0px 4px $surrogate-3;

$shadow-input-listbox-surrogate: inset 0rem 0rem 0rem 0.125rem
  $surrogate-4;
$shadow-input-listbox-turquoise: inset 0rem 0rem 0rem 0.125rem
  $turquoise-4;
$shadow-input-listbox-orange: inset 0rem 0rem 0rem 0.125rem $orange-4;

$shadow-input-listbox-surrogate-active: inset 0rem 0rem 0rem 0.125rem
  $surrogate-9;
$shadow-input-listbox-turquoise-active: inset 0rem 0rem 0rem 0.125rem
  $turquoise-9;
$shadow-input-listbox-orange-active: inset 0rem 0rem 0rem 0.125rem
  $orange-9;

$shadow-input-listbox-surrogate-highlight: 0rem 0rem 0rem 0.25rem
  $surrogate-3;
$shadow-input-listbox-turquoise-highlight: 0rem 0rem 0rem 0.25rem
  $turquoise-3;
$shadow-input-listbox-orange-highlight: 0rem 0rem 0rem 0.25rem $orange-3;
$shadow-map-info-card: 0px 1px 3px rgba(0, 0, 0, 0.08),
  0px 8px 16px rgba(0, 0, 0, 0.08);
$shadow-eng-map-menu-header: 0 0 2px 0 rgba(0, 0, 0, 0.1),
  0 0 27px 0 rgba(0, 0, 0, 0.12);
$shadow-eng-map-menu: 0 0 2px 0 rgba(0, 0, 0, 0.1),
  0 0 27px 0 rgba(0, 0, 0, 0.12);
$shadow-eng-map-menu-header: 0 20px 20px -10px rgba(0, 0, 0, 0.06);
$shadow-eng-map-filter-menu: 0px -4px 24px rgba(0, 0, 0, 0.08);
$shadow-eng-map-detail: -10px 0px 24px 1px rgba(0, 0, 0, 0.08);
$shadow-district-heat-map-search: 0 0 2px rgba(0, 0, 0, 0.08),
  0 8px 48px rgba(0, 0, 0, 0.08);

$shadow-eng-calorific-value-box: 0px 16px 64px rgba(0, 0, 0, 0.08);

$shadow-advisor-wrapper: 0 0 2px 0 rgba(0, 0, 0, 0.08),
  0 16px 40px 0 rgba(0, 0, 0, 0.12);
$shadow-advisor-message: 0 0 2px 0 rgba(0, 0, 0, 0.08),
  0 4px 24px 0 rgba(0, 0, 0, 0.12);

// new shadows from uikit

$shadow-card-soft: 0px 1px 2px rgba(0, 0, 0, 0.08),
  0px 0px 64px rgba(0, 0, 0, 0.08);

$shadow-input-search-focus: 0 0 0 4px rgba($surrogate-9, 0.12),
  0 0 0 2px inset $surrogate-9;

$shadow-regio-tariff-card: 0px 0px 4px rgba(0, 0, 0, 0.15),
  0px 4px 20px rgba(0, 0, 0, 0.15);

$shadow-input-search-focus: 0 0 0 4px rgba($surrogate-9, 0.12),
  0 0 0 2px inset $surrogate-9;

// Benefits
$shadow-benefits-deal-card: 0px 0px 2px rgba($neutral-12, 0.08),
  0px 16px 48px rgba($neutral-12, 0.08);

// new shadows from uikit

$shadow--large: 0px 0px 2px rgba(0, 0, 0, 0.08),
  0px 24px 64px rgba(0, 0, 0, 0.08);
$shadow--medium: 0px 0px 2px rgba(0, 0, 0, 0.08),
  0px 16px 48px rgba(0, 0, 0, 0.08);
$shadow--small: 0px 0px 1px rgba(0, 0, 0, 0.08),
  0px 8px 24px rgba(0, 0, 0, 0.08);

$shadow-small: 0 0 24px rgba($neutral-12, 0.08),
  0 0 1px rgba($neutral-12, 0.08);

$shadow-traffic-light: 0 4px 64px 4px rgba($neutral-12, 0.13);
