@import '../../styles/index';

.base {
  max-height: 100%;
  height: 53px;
  color: $surrogate-9;
}

.invert {
  color: $neutral-0;
}
