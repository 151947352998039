@import '../../../styles/index';

.base {
  font-size: $font-size-18;
  font-weight: $font-weight-light;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  @include media-from(sm) {
    margin-bottom: 2rem;
  }
}
