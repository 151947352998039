@import '../../styles/index';

.base {
  position: relative;
}

.checkbox-row {
  label,
  input {
    user-select: none;
    cursor: pointer;
  }
}

.text {
  font-size: $font-size-16;
  font-weight: $font-weight-light;

  @include media-from(sm) {
    font-size: $font-size-18;
  }
}
