// Cookie Consent
$index-cookie-consent: 9;

// Back to Top
$index-back-to-top: 3;

// Button
$index-button-modal: 6;
$index-button-modal-backdrop: 1;
$index-button-modal-wrapper: 2;

// Configurator
$index-configurator-header: 1;
$index-configurator-price-header-info: 5;
$index-configurator-progress: 5;

// Configuratorv2
$index-configuratorV2-header: 10;
$index-configuratorV2-navigation: 5;
$index-configuratorV2-navigation-desktop: 10;

// Listbox
$index-listbox-option: 5;

// Container
$index-container-top: 1;
$index-container: 2;

// Flip Layer
$index-fliplayer-front: 2;

// Form
$index-form-field: 1;
$index-form-field-dropdown-list: 6;

// Fullscreen Loader
$index-fullscreen-loader: 11;

// Header
$index-global-header: 8;
$index-global-navigation: 1;
$index-header: 5;
$index-navswitch: 1;
$index-page-header: 4;
$index-page-header-container: 1;
$index-page-header-backdrop: 3;

// Guided Box
$index-guided-box: 3;

// Map
$index-header-map: 2;
$index-map-cluster: 1;
$index-map-cluster-span: 2;
$index-map-marker: 1;
$index-map-marker-active: 11;
$index-map-menu: 4;
$index-map-filter-menu: 2;
$index-map-menu-fullscreen: 11;
$index-map-toggle: 2;
$index-map-details: 2;
$index-map-loading-overlay: 7;

// Lightbox
$index-light-box: 10000;

// Modal
$index-modal-conent: 10;
$index-modal-overlay: 12;
$index-modal-close-icon: 14;

// Project Navigation
$index-project-nav: 8;

// Showcase
$index-showcase-badge: 2;
$index-showcase-badge-text: 1;

// Stagebox
$index-stagebox-children: 2;
$index-stagebox-button: 1;
$index-stagebox-bg-white-bar: 2;

// Teaser Tile Badge
$index-teaser-tile-badge: 2;
$index-teaser-tile-badge-wrapper: 1;

// Video
$index-video-thumbnail: 1;

// Utility
$index-zero: 0;
$index-negative: -1;

// PromoBanner
$index-promo-banner: 4;

// AmazingNumber
$index-amazing-number: 2;

// Advisor
$index-advisor: 4;
$index-advisor-active: 12;
$index-advisor-overlay: 14;

// magazine-hero-content
$index-magazine-hero-content: 2;

// Karriereschritte
$index-timeline-item: 3;
$index-timeline-progress: 2;

// Search
$index-search-overlay: 9;
$index-search-scrim: 10;
$index-search-content: 11;

// CMDK
$index-cmdk-dialog: 20;

// NoticeBar
$index-notice-bar: 10;
