@import '../../styles/index';

.wrapper {
  display: block;
  height: 26px;
  width: 26px;
  position: relative;
  flex-shrink: 0;
}

.checkmark,
.minus {
  display: none;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;

  &:checked + .checkbox {
    border-color: transparent;

    &::before {
      transform: scale(1);
      border-radius: $border-radius-small;
    }

    &.inverse .checkmark {
      color: $surrogate-9;
    }

    .checkmark {
      display: block;
    }
  }

  &:indeterminate + .checkbox {
    border-color: transparent;

    &::before {
      transform: scale(1);
      border-radius: $border-radius-small;
    }

    &.inverse .minus {
      color: $surrogate-9;
    }

    .minus {
      display: block;
    }
  }

  &:focus + .checkbox {
    border-color: $surrogate-9;
  }
}

.checkbox {
  height: 100%;
  width: 100%;
  background-color: $neutral-0;
  border-radius: $border-radius-small;
  border-width: 2px;
  border-style: solid;
  border-color: $surrogate-6;
  transition: border-color $duration-default;

  &.error {
    border-color: $red-6;
  }

  &:hover {
    cursor: pointer;
  }

  // before element is the fill

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: 0;
    background-color: $surrogate-9;
    transform: scale(0);
    transition: transform $duration-default, border-radius $duration-default;
    pointer-events: none;
  }

  &.inverse {
    &::before {
      background-color: $neutral-0;
    }

    .checkmark,
    .minus {
      color: $neutral-0;
    }
  }
}

.checkmark,
.minus {
  height: 100%;
  width: 100%;
  color: $neutral-0;
  position: relative;
  z-index: 1;
  transition: color $duration-default;
  pointer-events: none;
}
