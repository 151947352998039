@import '../../styles/index';

.base {
  width: 100%;
}

.fields-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  @include media-from(xs) {
    flex-direction: row;
    & > div:not(:last-child) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
}

.title {
  margin-top: 0;
}
