@import '../../../styles/index';

.base {
  margin: 0;
}

.item {
  display: flex;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: rgba($surrogate-9, 0.1);
  }

  .text {
    font-size: $font-size-18;
    color: $surrogate-7;
    margin-left: 12px;
  }

  &.active {
    color: $surrogate-9;
  }
}
