@import '../../styles/index';

.base {
  display: flex;
  justify-content: space-between;
  padding: 1.125rem;
  font-size: $font-size-18;

  @include media-to(sm) {
    flex-direction: column;
  }
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 14px;

  @include media-to(sm) {
    margin-right: 0;
    justify-content: center;
  }
}

.leftContent {
  display: flex;

  @include media-to(sm) {
    flex-direction: column;
    text-align: center;
  }
}

.right-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.editIcon {
  display: flex;
  align-items: center;
  color: $surrogate-9;
}

.success {
  color: $green-9;
}

.error {
  color: $red-9;
}
