@import '../../styles/index';

.base {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 1rem);
  padding: 2.5rem 1rem;
  border-radius: 0.375rem;
  background-color: $neutral-0;
  box-shadow: $shadow-map-info-card;
  text-align: center;
  z-index: $index-map-loading-overlay;

  @include media-from(sm) {
    width: 100%;
    max-width: 40rem;
    padding: 3rem;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($neutral-0, 0.8);
  z-index: $index-map-loading-overlay;
}
