@import '../../styles/index';

.wrapper {
  display: none;

  @include media-from(sm) {
    margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
  }
}
